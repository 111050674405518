<template>
  <section
    id="main-desc"
  >
    <base-left-photo
      :src="require('@/assets/main_desc-1.jpg')"
      subheading="ROBOTIC TECHNOLOGY"
      html="Intelligent manufacturing, storage, logistics and lean management system solutions to revolutionize your business. Mobile robots to flexibly integrate different functional modules according to the process flow of a various industries. Versatile and flexible integration with WMS, WCS, MES, and other IT systems."
      color="black--text"
    >
    </base-left-photo>
    <base-right-photo
      :src="require('@/assets/main_desc-2.jpg')"
      subheading="SMART FACTORY/WAREHOUSE"
      html="Smart factory brings together digital and physical systems with the Internet of Things (IoT). These systems, complete with wireless connection, sensors, and data collection program will enable an effective IIoT-enabled workplace that is efficient, and productive, reduces downtime and costs that come with it, improves safety of production by monitoring potential failure and predictive maintenance in your operations.<br><br>Transforms warehouse operations with 'Goods To People' system into new, efficient automated warehouses with robot intelligent storage solution. Respond quickly to massive and fluctuating business needs with Intelligent warehouse management system, complete with the business of picking, shelving and counting goods to people."
      color="black--text"
    >
    </base-right-photo>
    <base-left-photo
      :src="require('@/assets/main_desc-4.jpg')"
      subheading="SOFTWARE INTEGRATION"
      html="Hassle-free, seamless integration with other software systems such as ERP, WCS, WMS, MES, and other IT systems."
      color="black--text"
    >
    </base-left-photo>
    <br>
    <br>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainDesc',
  }
</script>
